/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// begin :button mark complete

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

  /* Primary Buttons */

  .btn-primary,
  .btn-primary-hover,
  .btn-primary-disabled {
    @apply rounded-md bg-mint-500 px-2.5 py-2.5 ml-2 mr-2 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-mint-500 focus:ring-opacity-50;
    font-size: 14px;
  }

  .btn-primary-hover {
    @apply hover:bg-mint-400 px-4;
  }

  .btn-primary-disabled {
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }

  // Button with Icon
  .btn-icon,
  .btn-hover-icon,
  .btn-disabled-icon {
    @apply inline-flex items-center gap-x-1.5 rounded-md bg-mint-500 pl-2 pr-4 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-mint-500 focus:ring-opacity-50;
    font-size: 14px;
  }

  .btn-hover-icon {
    @apply hover:bg-mint-400;
  }

  .btn-disabled-icon {
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }

  // Danger Button
  .btn-danger,
  .btn-danger-hover,
  .btn-danger-disabled {
    @apply inline-flex items-center gap-x-2 rounded-md bg-grapefruit-500 px-4 py-2 text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-grapefruit-500 focus:ring-opacity-50;
    font-size: 14px;
    display: inline-block;
    padding: 0.5rem 1rem;
  }

  .btn-danger-hover {
    @apply hover:bg-grapefruit-400 px-4;
  }

  .btn-danger-disabled {
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }

  // Full Width Button
  .btn-full-width,
  .btn-hover-full-width,
  .btn-disabled-full-width {
    @apply flex w-full justify-center rounded-md bg-mint-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-mint-500 focus:ring-opacity-50;
    font-size: 14px;
  }

  .btn-hover-full-width {
    @apply hover:bg-mint-400;
  }

  .btn-disabled-full-width {
    @apply disabled:cursor-not-allowed disabled:opacity-50;
  }

  /* Secondary Buttons */

  // Standard Secondary Button

  .btn-secondary,
  .btn-secondary-hover,
  .btn-secondary-disabled {
    @apply rounded-md bg-white px-1 py-2.5 ml-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50;
    white-space: nowrap;
    overflow: hidden; 
    font-size: 14px;
  }

  .btn-secondary-hover {
    @apply hover:bg-gray-50;
  }

  .btn-secondary-disabled {
    @apply disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:shadow-none;
  }

  // Secondary Button with Icon

  .btn-secondary-icon,
  .btn-secondary-icon-hover,
  .btn-secondary-icon-disabled {
    @apply inline-flex items-center gap-2 rounded-md bg-white pl-2.5 pr-4 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50;
    font-size: 14px;
  }

  .btn-secondary-icon-hover {
    @apply hover:bg-gray-50;
  }

  .btn-secondary-icon-disabled {
    @apply disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:shadow-none;
  }

  /* Tertiary Buttons */

  // Standard Tertiary

  .btn-tertiary,
  .btn-tertiary-hover,
  .btn-tertiary-disabled {
    @apply rounded-md bg-white px-2.5 py-2.5 ml-2 cursor-pointer text-sm font-semibold text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50;
    font-size: 14px;
  }

  .btn-tertiary-hover {
    @apply hover:bg-gray-100 px-4;
  }

  .btn-tertiary-disabled {
    @apply disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:shadow-none;
  }
  
  // Tertiary with Icon

  .btn-tertiary-icon,
  .btn-tertiary-icon-hover,
  .btn-tertiary-icon-clicked {
    @apply inline-flex items-center gap-2 rounded-md px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50;
    font-size: 14px;
  }

  .btn-tertiary-icon-hover {
    @apply hover:bg-gray-200;
  }

  .btn-tertiary-icon-clicked {
    @apply bg-gray-300;
  }

  // Tertiary with Icon Only

  .btn-tertiary-icon-only,
  .btn-tertiary-icon-only-hover,
  .btn-tertiary-icon-only-clicked {
    @apply inline-flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50;
  } 

  .btn-tertiary-icon-only-hover {
    @apply bg-transparent hover:bg-gray-100;
  }

  .btn-tertiary-icon-only-clicked {
    @apply bg-gray-200;
  }

  // Tertiary for Danger

  .btn-tertiary-danger,
  .btn-tertiary-danger-hover,
  .btn-tertiary-danger-disabled {
    @apply rounded-md px-2.5 py-2.5 text-sm font-semibold text-grapefruit-500 focus:outline-none focus:ring-2 focus:ring-grapefruit-400 focus:ring-opacity-50;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden; 
    font-size: 14px;
  }

  .btn-tertiary-danger-hover {
    @apply hover:bg-grapefruit-50;
  }

  .btn-tertiary-danger-disabled {
    @apply disabled:bg-gray-200 disabled:text-grapefruit-400 disabled:cursor-not-allowed disabled:shadow-none;
  }

  /* Plain Buttons */

  .btn-plain,
  .btn-plain-hover,
  .btn-plain-disabled {
    @apply bg-mint-50 mt-2 px-4 py-2 text-sm text-mint-500 font-semibold focus:outline-none focus:ring-2 focus:ring-opacity-50 rounded;
  }

  .btn-plain-hover {
    @apply hover:bg-mint-50 hover:text-mint-500 focus:ring-mint-500;
  }

  .btn-plain-disabled {
    @apply disabled:text-mint-400 disabled:cursor-not-allowed disabled:shadow-none;
  }

  /* Toggle Buttons */

  // Standard Toggle

  .btn-toggle-success,
  .btn-toggle-success-hover,
  .btn-toggle-success-disabled {
    @apply inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-mint-500 focus:ring-opacity-50 focus:bg-gray-200 focus:ring-gray-500 focus:border-gray-500 hover:bg-gray-200;  }

  .btn-toggle-success-hover {
    @apply hover:bg-gray-200;
  }

  .btn-toggle-success-disabled {
    @apply disabled:opacity-50 disabled:cursor-not-allowed;
  }

  // Segmented Control Group

  .btn-segmented-control-button {
    @apply relative inline-flex items-center px-2.5 py-2 text-xs font-medium border border-gray-200 bg-white hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500;
  }

  .btn-segmented-control-button:focus {
      @apply focus:bg-gray-200 focus:ring-gray-500 focus:z-10;
  }

  .btn-segmented-control-button:first-child {
      @apply rounded-l-md;
  }

  .btn-segmented-control-button:last-child {
      @apply rounded-r-md;
  }


  /* Toggle Switch Button */

  /* Styles when the toggle is switched off (default state) */
  .custom-green-toggle .mdc-switch__track {
    background-color: #ccc; 
  }

  .custom-green-toggle .mdc-switch__handle-track {
    background-color: #fff; 
  }
  
  .custom-green-toggle .mdc-switch__handle .mdc-elevation-overlay {
    background-color: #ccc; 
  }

  /* Styles when the toggle is switched on */

  .custom-green-toggle.mat-mdc-slide-toggle-checked .mdc-switch__track {
    background-color: #4caf50 !important; 
  }

  .custom-green-toggle.mat-mdc-slide-toggle-checked .mdc-switch__handle-track {
    background-color: #c8e6c9;
  }

  .custom-green-toggle.mat-mdc-slide-toggle-checked .mdc-switch__handle .mdc-elevation-overlay {
    background-color: #4caf50; 
  }

  /* NAVIGATION BAR */

  .header-bar {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 4rem; 
    padding: 0 1rem;
    z-index: 49;
    background-color: #ffffff; 
  }
  
  .header-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem; 
  }
  
  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem; 
    padding: 0.5rem 1rem; 
    font-size: 14px; 
    font-weight: 500; 
    border-radius: 0.25rem; 
    cursor: pointer;
    text-decoration: none;
    color: #4a5568; 
    background-color: transparent;
    border: 1px solid transparent;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }
  
  .header-button.active,
  .header-button:hover {
    background-color: #f7fafc; 
    color: #2d3748;
  }
  
  .icon {
    width: 1.25rem; 
    height: 1.25rem; 
  }

  .header-button.active mat-icon {
    color: #36cc9f;
  }
  
}

/* SIDEBAR */

.nav-container {
  background-color: #1a202c; 
  border-right: 1px solid #cbd5e0; 
}

.nav-header {
  display: flex;
  align-items: center;
  height: 5rem; 
  padding: 1.5rem 1.5rem 0 1.5rem; 
}

.logo-light {
  display: block;
  width: 7.5rem; 
}

.dark .logo-light {
  display: none;
}

.logo-dark {
  display: none;
  width: 7.5rem;
}

.dark .logo-dark {
  display: flex;
}



.button-complete {
  color: #36cc9f !important;
  width: 167px;
  height: 42px;
  appearance: none;
  background-color: transparent;
  border: 1px solid #36cc9f;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin: 0;
  outline: none;

  .mat-icon {
    vertical-align: middle;
  }
}

.button-complete:hover {
  color: #fff;
  background-color: #36cc9f;
}

.addBoardDialog .mat-horizontal-stepper-header-container {
  display: none !important;
}
